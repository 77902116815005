// @import
// 		"grid",
//     "flexboxgrid",
//     "swipebox",
// 		"imports"
// ;

@import url(https://fonts.googleapis.com/css?family=Roboto:400,300,500|Montserrat:400,700);
@import url(https://rsms.me/inter/inter.css);

@mixin vendor-prefix($name, $argument) {
  -webkit-#{$name}: #{$argument};
  -moz-#{$name}: #{$argument};
  -ms-#{$name}: #{$argument};
  -o-#{$name}: #{$argument};
  #{$name}: #{$argument};
}

@font-face {
  font-family: "color-emoji";
  src: local("Apple Color Emoji"),
       local("Segoe UI Emoji"), 
       local("Segoe UI Symbol"),
       local("Noto Color Emoji");
}

/*Montserrat Font family */

@font-face {
    font-family: 'MontserratLocal';
    src: url('/font/MontserratRegular/MontserratRegular.eot');
    src: url('/font/MontserratRegular/MontserratRegular.eot?#iefix') format('embedded-opentype'),
    url('/font/MontserratRegular/MontserratRegular.woff') format('woff'),
    url('/font/MontserratRegular/MontserratRegular.ttf')  format('truetype'),
    url('/font/MontserratRegular/MontserratRegular.svg#MontserratRegular') format('svg');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'MontserratLocal';
    src: url('/font/MontserratBold/MontserratBold.eot');
    src: url('/font/MontserratBold/MontserratBold.eot?#iefix') format('embedded-opentype'),
    url('/font/MontserratBold/MontserratBold.woff') format('woff'),
    url('/font/MontserratBold/MontserratBold.ttf')  format('truetype'),
    url('/font/MontserratBold/MontserratBold.svg#MontserratBold') format('svg');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'MontserratLocal';
    src: url('/font/MontserratLight/MontserratLight.eot');
    src: url('/font/MontserratLight/MontserratLight.eot?#iefix') format('embedded-opentype'),
    url('/font/MontserratLight/MontserratLight.woff') format('woff'),
    url('/font/MontserratLight/MontserratLight.ttf')  format('truetype'),
    url('/font/MontserratLight/MontserratLight.svg#MontserratLight') format('svg');
    font-weight: normal;
    font-style: normal;
}

/* Resets */

*:not(select) {
    margin: 0;
    padding: 0;
    font-family: 'MontserratLocal', 'Montserrat', 'Helvetica Neue', Helvetica, Roboto, Arial, sans-serif;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
}

html {
  -ms-touch-action: manipulation;
  touch-action: manipulation;
}

#simplbox {
  z-index: 9999; /* Use whatever you want */
  ms-touch-action: none; /* Removes touch action */
  touch-action: none; /* Removes touch action */
}

html, body {
    width: 100%;
    height: 100%;

}

body {
    &.lightbox-active {
        overflow-x: hidden;
    }
}

a {
    cursor: pointer;
    text-decoration: none;
    color: #000;
    font-style: normal;
}

ul {
    list-style: none;
    list-style-position: inside;

    li {
        color: rgba(0, 0, 0, 0.6);
        font-weight: 300;
        line-height: 1.75;
        text-align: center;
    }
}

$spacing-unit: 16px;


/* Main content */

.intro-container {
  min-height: calc(100vh - 4rem);
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  
  .intro {
    max-width: 45rem;
    flex: 1 0 auto;
    padding: 0.75rem;
    width: 100%;
    
    *:not(.fab):not(.emoji) {
      font-family: 'Inter';
      letter-spacing: -0.05rem;
      font-weight: 400;
      font-size: 1.8rem;
      color: black;
      line-height: 1.35;
    }
	  
    .emoji {
    	font-family: sans-serif, apple color emoji, segoe ui emoji, segoe ui symbol, noto color emoji;
    }

    strong, .strong {
      font-weight: 600 !important;
      text-decoration: none;
      color: black;
    }

    a {
      &:hover {
        text-decoration: underline;
      }
    }
  }
  
  + .social {
     height: 4rem;
     text-align: center;
     padding: 1rem 0 1.7rem;
     
     a {
       display: inline-block;
       color: black;
       font-size: 1.5rem;
       margin: 0 0.3rem;
     }
  }
}